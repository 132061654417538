<template>
  <div>
    <general-table
      ref="userTable"
      :api-url="APIURL"
      :add-type="addType"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :type="type"
      :columns="columns"
      :guard="guard"
      :viw-component="viwComponent"
    >
      <template #cell(active_type)="data">
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.active_type)"
          @click="changeActiveType(data.item)"
        >
          {{ data.item.active_type }}
        </b-badge>
      </template>
    </general-table>
    <div>
      <b-modal
        id="activeType"
        ref="active-type-modal"
        hide-footer
        centered
        title="Change Active Type Status"
      >
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group label="Activation Types">
              <b-form-select
                v-model="userForm.active_type"
                value-field="value"
                text-field="text"
                :options="types"
                right
              >
                <template #first>
                  <b-form-select-option
                    disabled
                    :value="userForm.active_type"
                  >
                    {{ userForm.active_type }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-1"
              @click.prevent="changeType()"
            >
              Save
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="userACtiveType"
        ref="active-type-modal"
        hide-footer
        centered
        title="Change Active Type Status"
      >
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group label="Activation Types">
              <b-form-select
                v-model="userForm.active_type"
                value-field="value"
                text-field="text"
                :options="activeType"
                right
              >
                <template #first>
                  <b-form-select-option
                    disabled
                    :value="userForm.active_type"
                  >
                    {{ userForm.active_type }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-1"
              @click.prevent="changeUserType()"
            >
              Save
            </b-button>
            <b-button
              variant="outline-danger"
              class="closeModal"
              @click="closeModal()"
            >
              Cancel
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
    <div>
      <b-modal
        ref="view-modal"
        hide-footer
        centered
      >
        <view-user />
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import ViewUser from './control-users/ViewUser.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: { GeneralTable, ViewUser },
  data() {
    return {
      APIURL: 'users',
      addType: 'Add User',
      addComponentName: 'add-user-merchant',
      editComponent: 'edit-user-merchant',
      viwComponent: 'show-user-merchant',
      type: 'page',
      guard: 'merchant_users',
      columns: [
        'select',
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'phone', sortable: true },
        { key: 'email', sortable: true },
        { key: 'role', label: 'Job' },
        { key: 'gender', sortable: true },
        { key: 'active_type', label: 'Active', sortable: true },
        { key: 'actions' },
      ],
      activeType: [
        { text: 'Accepted', value: 'Accepted' },
        { text: 'Rejected', value: 'Rejected' },
        { text: 'Pending', value: 'Pending' },
        { text: 'Hold', value: 'Hold' },
      ],
      loader: false,
      userForm: {},
      types: {},
      currentTime: '',
      role: '',
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()

    return {
      formData, setFormData,
    }
  },
  created() {
    this.getCurrentDateTime()
  },
  methods: {
    changeActiveType(data) {
      this.userForm = data
      this.filterTypes(data.active_type)
      this.$bvModal.show('activeType')
    },
    closeModal() {
      this.$bvModal.hide('activeType')
      this.$bvModal.hide('userACtiveType')
    },
    changeType() {
      if (this.userForm?.id) {
        this.loader = true
        if (this.userForm.active_type === 'Accepted') {
          this.userForm.activation_at = this.currentTime
        } else {
          this.userForm.activation_at = ''
        }
        this.setFormData(this.userForm)
        this.formData.append('_method', 'PUT')
        axios.post(`users/${this.userForm.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.closeModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Succesfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        }).catch(() => {
          // this.formData = new FormData()
        }).finally(() => {
          this.loader = false
        })
      }
    },
    changeUserType() {
      axios.post('users/edit-active-status', {
        users: this.$refs.userTable.selected,
        active_type: this.userForm.active_type,
      }).then(res => {
        if (res.status === 200) {
          this.closeModal()
          this.$refs.userTable.getAllData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Accepted') return 'success'
      // eslint-disable-next-line eqeqeq
      if (type == 'Pending') return 'info'
      // eslint-disable-next-line eqeqeq
      if (type == 'Hold') return 'warning'

      return 'danger'
    },
    filterTypes(type) {
      const filteredType = this.activeType.filter(item => item.text !== type)
      this.types = filteredType
    },
    getCurrentDateTime() {
      const today = new Date()

      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`

      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`

      const dateTime = `${date} ${time}`

      this.currentTime = dateTime
    },
  },
}
</script>

<style scoped>
.change-status {
  cursor: pointer;
}
</style>
